.rectangle {
  margin: 49px auto;
  height: 631px;
  width: 1541px;
  .rectangleText {
    color: #171819;
    width: fit-content;
    padding: 98px 0 0 90px;
    display: flex;
    flex-direction: column;
    gap: 19px;
    h1 {
      font-size: 51px;
      font-weight: 900;
      line-height: 63px;
    }

    p {
      line-height: 32px;
    }

    .rectangleCatalogeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 23px;
      background-color: #171819;
      width: 251px;
      height: 66px;
      border-radius: 5px;
      color: white;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 90%;
      }
    }
  }
  .rectangleButtonSwitch {
    margin: 43px 15px 0 0;
    width: 67px;
    height: 67px;
    border-radius: 100%;

    background-color: white;
    color: #171819;

    font-size: 2rem;
    font-weight: 900;
    font-family: "RF Dewi";
  }

  border-radius: 7px;

  background: url("../../images/mainBG.png") center no-repeat;
}
