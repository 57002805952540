* {
  margin: 0;
  padding: 0;
  font-family: "RF Dewi";
  background: none;
  border: none;
  text-decoration: none;
}

button {
  cursor: pointer;
}
@font-face {
  font-family: "RF Dewi";
  src: local("RF Dewi Bold"), local("RF-Dewi-Bold"),
    url("./fonts/RFDewi-Bold.woff2") format("woff2"),
    url("./fonts/RFDewi-Bold.woff") format("woff"),
    url("./fonts/RFDewi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "RF Dewi";
  src: local("RF Dewi Semibold"), local("RF-Dewi-Semibold"),
    url("./fonts/RFDewi-Semibold.woff2") format("woff2"),
    url("./fonts/RFDewi-Semibold.woff") format("woff"),
    url("./fonts/RFDewi-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "RF Dewi";
  src: local("RF Dewi Regular"), local("RF-Dewi-Regular"),
    url("./fonts/RFDewi-Regular.woff2") format("woff2"),
    url("./fonts/RFDewi-Regular.woff") format("woff"),
    url("./fonts/RFDewi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "RF Dewi";
  src: local("RF Dewi Condensed Black"), local("RF-Dewi-Condensed-Black"),
    url("./fonts/RFDewiCondensed-Black.woff2") format("woff2"),
    url("./fonts/RFDewiCondensed-Black.woff") format("woff"),
    url("./fonts/RFDewiCondensed-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
