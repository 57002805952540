.products {
  display: flex;
  margin: 0 auto;
  width: 1362px;
  flex-direction: column;
  gap: 80px;
  .productsMain {
    h2 {
      font-weight: 900;
      font-size: 31px;
      line-height: 42px;
    }
    h3 {
      font-weight: 700;
      font-size: 19px;
      line-height: 28px;
    }

    button {
      border-bottom: 1px solid black;
      font-weight: 800;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: rgb(73, 73, 73);
      }
    }

    .productsMainHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .productsMainItems {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .productsMainItem {
        display: flex;
        flex-direction: column;

        text-align: start;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border: 1px solid white;
        &:hover {
          border: 1px solid rgba(128, 128, 128, 0.247);
          border-radius: 15px;
        }

        position: relative;
        .productsMainItemButtonAdd {
          position: absolute;
          border: none;
          right: 20px;
          top: 20px;
        }
      }
    }
  }
}
