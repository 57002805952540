.calculateBG {
  display: flex;
  margin: 49px auto;
  height: 631px;
  width: 1541px;
  background: linear-gradient(
    267.03deg,
    #0d99f8 0.94%,
    #21a0f8 5.36%,
    #54b5ff 27.35%,
    #0dbbf8 79%,
    #0ddbf8 97.53%
  );
  border-radius: 7px;
  .calculate {
    padding: 98px 90px;
    .calculateLeftText {
      width: 738px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: 51px;
      }
      span {
        font-weight: 700;
        font-size: 19px;
        line-height: 32px;
        color: white;
      }
    }
  }
}
