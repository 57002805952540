.header {
  background-color: #121214;

  font-family: "RF Dewi";

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100px;
    margin: 0 280px;
    nav {
      ul {
        display: flex;
        gap: 48px;

        li {
          display: flex;

          a {
            color: white;
            text-decoration: none;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }

    .headerRight {
      display: flex;
      align-items: center;
      gap: 32px;
      color: #8c8f96;
      font-size: 13px;
      font-weight: 700;
      div {
        display: flex;
        align-items: center;
        gap: 6px;

        span {
          background-color: #49d0ff;
          color: white;
          text-align: center;

          height: 18px;
          width: 18px;
          border-radius: 100%;
        }
      }
    }
  }
}
